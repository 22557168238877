import { useState } from 'react';
import * as api from '../api';
import { site } from '../config';
import { passwordResetURL } from '../config/MiscAPIConfig';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import PasswordStrengthIndicator from '../generic/PasswordStrengthIndicator';
import Toast from '../generic/Toast/Toast';
import { InputGroup } from '../generic/forms';
import SubmitButton from '../generic/forms/SubmitButton';
import delay from '../utils/delay';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export default function PasswordResetSection() {
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');

    const updatePassword = async () => {
        setError(null);
        setIsLoading.toTrue();
        try {
            const urlParams = new URLSearchParams(location.search);
            const token = urlParams.get('token');

            await api.postJson(passwordResetURL(), {
                password,
                checkPassword,
                token,
            });

            Toast.call({ message: 'Ditt passord er endret. Vennligst logg inn.' });
            await delay(3_000);
            location.replace(site.loginURL());
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <section className="white form-section">
            <div className="form-section-info">
                <h2>Passord</h2>
                <p>Passordet må bestå av minst 6 tegn.</p>
            </div>
            <fieldset disabled={isLoading}>
                <div>
                    <form className="formVmp" onSubmit={preventDefault(updatePassword)}>
                        <ErrorBox errors={error} className="margin-bottom" />
                        <InputGroup
                            label="Passord *"
                            id="password"
                            name="password"
                            onChange={eventTargetValue(setPassword)}
                            value={password}
                            type="password"
                        />
                        <PasswordStrengthIndicator password={password} />
                        <InputGroup
                            label="Bekreft passord *"
                            id="checkPassword"
                            name="checkPassword"
                            onChange={eventTargetValue(setCheckPassword)}
                            value={checkPassword}
                            type="password"
                        />
                        <div className="form-buttons-nomargin">
                            <SubmitButton isLoading={isLoading}>Oppdater passordet</SubmitButton>
                        </div>
                    </form>
                </div>
            </fieldset>
        </section>
    );
}
